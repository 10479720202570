import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import {
  FormGroup,
  FormControl,
  FormLabel,
  Checkbox,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { MiniCursoVideo } from "./MiniCursoVideo";
import { trackMetric } from "../../../actions/plataformActions";
import {
  createBiblioSubjectMaterialUser,
  deleteBiblioSubjectMaterialUser,
} from "../../../services/biblio-subject-material-user";
import { licensePdf } from "../../../services/mini-courses";
import * as S from "./styles";

export const MiniCursoContent = ({
  goal = "",
  apostilas = [],
  videos = [],
  extras = [],
  concursoId,
  moduleId,
  subjectId,
  miniCourseId,
  biblioUserData = [],
  refetchBiblioUserData,
}) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData);

  const { mutate: licensePdfMutation, isLoading: isLicensePdfLoading } =
    useMutation({
      mutationFn: ({ filename, cpf, phoneNumber }) =>
        licensePdf(filename, cpf, phoneNumber),
      onSuccess: (res) => {
        window.open(res.data);
      },
    });

  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleDownloadStudyBooklet = useCallback(
    async (filename, moduleId, miniCourseId, resourceName) => {
      if (filename) {
        licensePdfMutation({
          filename,
          cpf: userData.data.cpf,
          phoneNumber: userData.data.mobile,
        });

        dispatch(
          trackMetric("MiniCurso - Download Apostila", {
            target: "resource",
            trigger: "check",
            parentResource: moduleId,
            resource: miniCourseId,
            resourceName,
            filter: location.href.split("/").pop(),
          })
        );
      }
    },
    [dispatch, userData.data.cpf, userData.data.mobile]
  );

  const handleClickBiblioUserData = useCallback(
    async (resourceId, resourceName, checked) => {
      if (checked) {
        await createBiblioSubjectMaterialUser({
          material: moduleId,
          subjectId: subjectId,
          concursoId,
          resourceId,
          resourceName,
          week: 0,
        });
      } else {
        await deleteBiblioSubjectMaterialUser({
          material: moduleId,
          concursoId,
          resourceId,
          resourceName,
        });
      }

      refetchBiblioUserData();
    },
    [refetchBiblioUserData]
  );

  return (
    <S.MiniCursoModuleContent item xs={12} md={10}>
      <S.MiniCursoSection data-type="target">
        <S.MiniCursoSectionContent>
          <S.MiniCursoSectionTitle variant="h5">
            Objetivo
          </S.MiniCursoSectionTitle>
          <div id="goal" dangerouslySetInnerHTML={{ __html: goal }} />
        </S.MiniCursoSectionContent>
      </S.MiniCursoSection>

      {videos.length > 0 && (
        <S.MiniCursoSection>
          <S.MiniCursoSectionContent>
            <S.MiniCursoSectionTitle variant="h5">
              Vídeos
            </S.MiniCursoSectionTitle>

            <FormGroup style={{ width: "100%" }}>
              {videos.map((video) => (
                <>
                  <FormControl
                    key={video._id}
                    component="fieldset"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      maxWidth: "100%",
                      gap: 4,
                    }}
                  >
                    <Checkbox
                      id={video._id}
                      color="primary"
                      checked={biblioUserData.some(
                        (userData) => userData.resourceId === video._id
                      )}
                      onChange={(ev) =>
                        handleClickBiblioUserData(
                          video._id,
                          "videos",
                          ev.target.checked
                        )
                      }
                    />
                    <S.CustomFormLabel
                      component="label"
                      data-type={
                        video.youtube || video.vimeo ? "video" : "text"
                      }
                      htmlFor={
                        !video.youtube && !video.vimeo ? video._id : null
                      }
                      onClick={() => {
                        if (video.youtube || video.vimeo) {
                          setIsOpenVideo(true);
                          setSelectedVideo(video);
                        }
                      }}
                    >
                      {video.name}
                    </S.CustomFormLabel>
                  </FormControl>
                  {video.text && (
                    <div
                      style={{ paddingLeft: 42 }}
                      dangerouslySetInnerHTML={{ __html: video.text }}
                    />
                  )}
                </>
              ))}
            </FormGroup>
          </S.MiniCursoSectionContent>

          <MiniCursoVideo
            isOpen={isOpenVideo}
            video={selectedVideo}
            onClose={() => setIsOpenVideo(false)}
          />
        </S.MiniCursoSection>
      )}

        {apostilas.length > 0 && (
          <S.MiniCursoSection data-type="plus">
            <S.MiniCursoSectionContent>
              <S.MiniCursoSectionTitle variant="h5">
                Materiais Exclusivos
              </S.MiniCursoSectionTitle>

              <FormGroup style={{ width: "100%" }}>
                {apostilas.map((apostila) => (
                  <FormControl
                    key={apostila._id}
                    component="fieldset"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      maxWidth: "100%",
                      gap: 4,
                    }}
                  >
                    <Checkbox
                      id={apostila._id}
                      color="primary"
                      checked={biblioUserData.some(
                        (userData) => userData.resourceId === apostila._id
                      )}
                      onChange={(ev) =>
                        handleClickBiblioUserData(
                          apostila._id,
                          "apostilas",
                          ev.target.checked
                        )
                      }
                    />
                    <FormLabel component="label" htmlFor={apostila._id}>
                      {apostila.name}
                    </FormLabel>
                    <Button
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 4,
                        marginLeft: "auto",
                        backgroundColor: "#fc7954",
                        color: "white",
                        width: "120px",
                      }}
                      variant="contained"
                      color="primary"
                      disabled={isLicensePdfLoading}
                      onClick={() =>
                        handleDownloadStudyBooklet(
                          apostila.filename,
                          moduleId,
                          miniCourseId,
                          apostila.name
                        )
                      }
                    >
                      {isLicensePdfLoading ? (
                        <CircularProgress size={20} color="white" />
                      ) : (
                        <span>Download</span>
                      )}
                    </Button>
                  </FormControl>
                ))}
              </FormGroup>

              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  * Encontrou erro? Reporte ao final desta página.
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  ** O download demora alguns segundos para iniciar, por conta do
                  licenciamento do PDF.
                </Typography>
              </div>
            </S.MiniCursoSectionContent>
          </S.MiniCursoSection>
        )}

        {extras.length > 0 && (
          <S.MiniCursoSection>
            <S.MiniCursoSectionContent>
              <S.MiniCursoSectionTitle variant="h5">
                Extras
              </S.MiniCursoSectionTitle>

              <FormGroup style={{ width: "100%" }}>
                {extras.map((extra) => (
                  <>
                    <FormControl
                      key={extra._id}
                      component="fieldset"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        maxWidth: "100%",
                        gap: 4,
                      }}
                    >
                      <Checkbox
                        id={extra._id}
                        color="primary"
                        checked={biblioUserData.some(
                          (userData) => userData.resourceId === extra._id
                        )}
                        onChange={(ev) =>
                          handleClickBiblioUserData(
                            extra._id,
                            "extra",
                            ev.target.checked
                          )
                        }
                      />
                      <FormLabel component="label" htmlFor={extra._id}>
                        {extra.name}
                      </FormLabel>
                    </FormControl>
                    <div
                      style={{ paddingLeft: 24 }}
                      dangerouslySetInnerHTML={{ __html: extra.text }}
                    />
                  </>
                ))}
              </FormGroup>
            </S.MiniCursoSectionContent>
          </S.MiniCursoSection>
        )}
    </S.MiniCursoModuleContent>
  );
};
