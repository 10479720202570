import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import * as S from "./styles";

export const MiniCursoSidebar = ({ modules, moduleId, selectModule }) => {
  const { push } = useHistory();

  return (
    <S.MiniCursoSidebar item xs={0} md={2}>
      {modules?.map((module) => (
        <S.MiniCursoSidebarItem
          key={module._id}
          id={module._id}
          data-selected={moduleId === module._id}
          onClick={() => {
            selectModule(module._id);
            push(`/mini-cursos/${module.subject}/${module._id}`);
          }}
        >
          <Typography variant="body2" color="textPrimary">
            {module.title}
          </Typography>
        </S.MiniCursoSidebarItem>
      ))}
    </S.MiniCursoSidebar>
  );
};
