import axios from "axios";
import api from "../api";

export function getMiniCourses(concursoId) {
  return api.get(`/mini-courses?concursoId=${concursoId}`);
}

export function licensePdf(filename, cpf, phoneNumber) {
  return axios.post(
    "https://us-central1-clippingcacd.cloudfunctions.net/license-pdf-apostila/license-pdf-apostila",
    {
      filename,
      document: cpf,
      phone_numer: phoneNumber,
    },
    {
      headers: {
        Authorization: localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    }
  );
}
