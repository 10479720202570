import {
  AppBar,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  FormLabel,
} from "@material-ui/core";
import styled from "styled-components";

export const MiniCursoGridContainer = styled(Grid)`
  background-color: #f7f8f9;
  font-family: "Proxima Nova", sans-serif;
`;

export const MiniCursoAppBar = styled(AppBar)`
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  background-color: white !important;
  padding-left: 16px;
  padding-right: 16px;
`;

export const MiniCursoAppBarTitle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const MiniCursoMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MiniCursoSidebar = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-right: 1px solid #e0e0e0;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const MiniCursoSidebarItem = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  border-radius: 4px;
  min-height: 48px;

  &[data-selected="true"] {
    border-left: 2px solid #f8a947;
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
    background-color: #f7f8f9;
  }
`;

export const MiniCursoModuleContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 400px !important;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 48px;
  padding-bottom: 48px;
  border-radius: 4px;

  @media (max-width: 1200px) {
    width: 100% !important;
    min-height: calc(100vh - 64px) !important;
    height: fit-content !important;
    padding: 16px !important;
  }
`;

export const MiniCursoSection = styled(Card)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff !important;
  padding: 24px !important;
  border-radius: 0px !important;

  &[data-type="target"] {
    background-color: #c8c8c84d !important;
  }

  &[data-type="plus"] {
    background-color: #fff5ec !important;
  }

  @media (max-width: 1200px) {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
`;

export const MiniCursoSectionTitle = styled(Typography)`
  font-family: "Proxima Nova", sans-serif;
`;

export const MiniCursoSectionContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
`;

export const CustomFormLabel = styled(FormLabel)`
  &[data-type="video"] {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: blue;
    }
  }
`;
