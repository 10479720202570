import { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, Typography } from "@material-ui/core";
import { getMiniCourses } from "../../services/mini-courses";
import { MiniCurso } from "./MiniCurso";
import * as S from "./styles";
import { isPlusUser } from "../../utils/user";

export default function MiniCursos() {
  const { push, goBack } = useHistory();

  const concursoId = useSelector((state) => state.concurso.active.concursoId);
  const { data: miniCourses, isLoading } = useQuery({
    queryKey: ["minicourses", concursoId],
    queryFn: () => getMiniCourses(concursoId),
    enabled: !!concursoId,
    initialData: {
      data: [],
    },
  });

  const [selectedMiniCourse, setSelectedMiniCourse] = useState(null);
  const [selectedMiniCourseModule, setSelectedMiniCourseModule] =
    useState(null);

  if (isLoading) {
    return (
      <S.MiniCoursesContainer>
        <CircularProgress />
      </S.MiniCoursesContainer>
    );
  }

  const handleMiniCourseClick = useCallback(
    (miniCourse) => {
      if (!selectedMiniCourse || selectedMiniCourse._id !== miniCourse._id) {
        setSelectedMiniCourse(miniCourse);
      } else {
        setSelectedMiniCourse(null);
      }
    },
    [selectedMiniCourse]
  );

  useEffect(() => {
    if (!isPlusUser()) {
      goBack();
    }
  }, []);

  useEffect(() => {
    if (miniCourses.data.length === 1) {
      handleMiniCourseClick(miniCourses.data[0]);
    }
  }, [miniCourses.data]);

  return (
    <S.MiniCoursesContainer>
      {miniCourses?.data?.map((miniCourse) => (
        <S.MiniCourseCardContent key={miniCourse._id}>
          <S.MiniCourseCard
            key={miniCourse._id}
            onClick={() => handleMiniCourseClick(miniCourse)}
            data-selected={selectedMiniCourse?._id === miniCourse._id}
          >
            <S.MiniCourseCardTitle>{miniCourse._id}</S.MiniCourseCardTitle>
          </S.MiniCourseCard>

          {selectedMiniCourse?._id === miniCourse._id && (
            <S.MiniCourseList>
              {miniCourse.documents.map((module) => (
                <S.MiniCourseModule
                  key={module._id}
                  onClick={() => {
                    setSelectedMiniCourseModule(module._id);
                    push(`/mini-cursos/${module.subject}/${module._id}`);
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ fontWeight: 300, fontFamily: "Proxima Nova" }}
                  >
                    {module.title}
                  </Typography>
                </S.MiniCourseModule>
              ))}
            </S.MiniCourseList>
          )}
        </S.MiniCourseCardContent>
      ))}

      <MiniCurso
        isOpen={!!selectedMiniCourseModule}
        miniCourseTitle={selectedMiniCourse?._id}
        modules={selectedMiniCourse?.documents ?? []}
        moduleId={selectedMiniCourseModule}
        selectModule={setSelectedMiniCourseModule}
        onClose={() => {
          setSelectedMiniCourseModule(null);
          setSelectedMiniCourse(null);
        }}
      />
    </S.MiniCoursesContainer>
  );
}
