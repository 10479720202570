import React, { Component } from 'react'
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { push } from 'react-router-redux';
import { withStyles } from 'material-ui/styles';
import { goBack } from 'react-router-redux';
import moment from 'moment';
import Dialog from '../../components/ModalScreen';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Button from 'material-ui/Button';
import ArrowLeft from 'material-ui-icons/ChevronLeft'
import LockIcon from 'material-ui-icons/Lock';
import { crudGetList } from '../../actions/dataActions';
import { SORT_ASC } from '../../reducers/resource/list/queryReducer';
import _ from 'lodash'; 

const styles = theme => ({
  dialog: {
    minHeight: '100%'
  },
  appBar: {
    top: 0,
    left: 'auto',
    right: 0,
    backgroundColor: '#FFF',
    transition: theme.transitions.create('width'),
    height: '65px',
    paddingRight: '24px',
    justifyContent: 'center',
    boxShadow: theme.shadows[27],
  },
  backContainer: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '18px'
    },
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  backIcon: {
    ...theme.typography.menu,
    color: theme.palette.text.clear,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '8px',
    paddingLeft: '0px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveDisplay: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit'
    }
  },
  toolbar: {
    padding: '0px'
  },
  titleContainer: {
    ...theme.typography.title,
    paddingTop: '4px',
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  menuIcons: {
    display: 'flex',
    color: theme.palette.common.white,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  arrowLeft: {
    height: '40px',
    width: '40px',
    color: theme.palette.black.a3,
    [theme.breakpoints.up('sm')]: {
      height: '25px',
      width: '25px',
      color: theme.palette.text.clear
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '70px',
    paddingLeft: '8px',
    paddingRight: '8px',
    minHeight: '230px',
    paddingBottom: '4px',
  },
  icon: {
    marginTop: '64px',
    fontSize: '30px',
    color: 'rgba(0, 0, 0, 0.3)',
  },
  title: {
    marginTop: '26px',
    color: 'rgba(0,0,0,0.7)',
    fontFamily: 'Proxima Nova',
    fontSize: '36px',
    fontWeight: 'bold',
    letterSpacing: '-1px',
    lineHeight: '36px',
    textAlign: 'center'
  },

  listaConcursosContainer: {
    marginTop: '26px',
    color: 'rgba(0,0,0,0.7)',
    fontFamily: 'Proxima Nova',
    fontSize: '18px',
    lineHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  listaConcursos: {
    // listStyleType: 'none',
    maxWidth: '600px',
  },

  link: {
    color: '#1953AB',
    cursor: 'pointer',
    outline: 'none'
  },
});

class Assinatura extends Component {
  constructor(props) {
    super(props)
  }

  componentWillMount() {
   
  }
  
  render() {

    const { classes, goBack } = this.props;
    
    return (
      <Dialog open={Boolean(open)} className={classes.dialog}>
        <AppBar className={classes.appBar} position='fixed'>
          <Toolbar className={classes.toolbar}>
            <div className={classes.backContainer}>
              <Button
                disableRipple
                disableFocusRipple
                aria-label="voltar"
                onClick={() => goBack()}
                className={classes.backIcon}
              >
                <ArrowLeft className={classes.arrowLeft}/> <div className={classes.responsiveDisplay}>voltar</div>
              </Button>
            </div>

            <div className={classes.titleContainer}>
              Librerar acesso a novo concurso
            </div>
            
            <div className={classes.menuIcons}>
            </div>
          </Toolbar>
        </AppBar>

        <div className={classes.header}>
          <LockIcon className={classes.icon}/>
          <div className={classes.title}>
          Para ter acesso a um novo concurso, acesse nosso checkout específico por meio do link abaixo:
          </div>
        </div>

        {/* CONTENT */}
        <div className={classes.listaConcursosContainer}>
          <ul className={classes.listaConcursos}>
            <li><a className={classes.link} href="https://checkout.clippingconcursos.com.br/acessar?gotoCheckout=true&category=cacd">Concurso de admissão à carreira de diplomata</a></li>
            <li><a className={classes.link} href="https://checkout.clippingconcursos.com.br/acessar?campaign=63c544087a735790ef95b643">Concurso para Oficial de Chancelaria</a></li>
            <li><a className={classes.link} href="https://checkout.clippingconcursos.com.br/acessar?gotoCheckout=true&category=abin">Concurso para agente da ABIN</a></li>
            <li><a className={classes.link} href="https://checkout.clippingconcursos.com.br/acessar?campaign=61e96701e8d16a62c1eaa288">Concursos para Tribunais e TRT</a></li>
            <li><a className={classes.link} href="https://checkout.clippingconcursos.com.br/acessar?campaign=61e96589d6de60d03d6a64a4">Concursos para a Polícia Federal</a></li>
            <li><a className={classes.link} href="https://checkout.clippingconcursos.com.br/acessar?campaign=61e96649e8d16a2420eaa20a">Concursos para a Polícia Rodoviávria Federal</a></li>
          </ul>
        </div>
        
      </Dialog>
    )
  }
}

const mapStateToProps = ( state ) => {
  return {
    subscription: state.user.subscription,
    concursos: _.values(state.concurso.data),
    plans: _.values(state.plan.data)
  }
}

const mapDispatchToProps = {
  pushRouter: (path) => push(path),
  goBack: () => goBack(),
  loadPlans: () => crudGetList('plan', {page: 1, perPage: 999}, {field: 'createdAt', order: SORT_ASC}, false),
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Assinatura);
